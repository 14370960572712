.ui-list {
  margin: 0;
  padding: 0;
  list-style: none;

  &--horizontal {
    flex-wrap: wrap;
  }
  &--hidden {
    display: none;
  }
}
.icon {
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}
