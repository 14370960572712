.card {
  background: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: relative;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  &--fluid {
    width: 100%;
  }

  &--fixed {
    width: 30rem;
  }
}
