@use '@/styles/settings/sizes';
@use '@/styles/settings/spacing';

.container {
  width: var(--container-width);
  max-width: var(--container-max-width);
  margin-right: auto;
  margin-left: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);

  &--default {
    width: var(--container-width);
    max-width: var(--container-max-width);
    margin-right: auto;
    margin-left: auto;
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
  }

  &--big {
    width: var(--big-container-width);
    max-width: var(--big-container-max-width);
    margin-right: auto;
    margin-left: auto;
    padding-left: var(--big-container-padding);
    padding-right: var(--big-container-padding);
  }

  &--flex {
    display: flex;
    flex-direction: row;
  }

  &--row {
    display: flex;
    flex-direction: row;
  }

  &--column {
    display: flex;
    flex-direction: column;
  }

  &--full {
    width: 100%;
    max-width: 100%;
  }

  &--full-left {
    width: 100%;
    max-width: 100%;
    padding-right: calc((100% - min(90%, 1200px)) / 2);
  }

  &--full-right {
    width: 100%;
    max-width: 100%;
    padding-left: calc((100% - min(90%, 1200px)) / 2);
  }
  &--hidden {
    display: none !important;
  }
}
